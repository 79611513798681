/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import { CardsComponent } from "components/CardsComponent";
import imagenContenido from "assets/images/sophos-imagec.jpg";

import logoSolution2 from "assets/images/plantman.png";
import cardImage1 from "assets/images/endpoint.png";
import cardImage2 from "assets/images/firewall.png";
import cardImage3 from "assets/images/cns.png";
import cardImage4 from "assets/images/email.png";
import { columnTypes } from "../page-headers";

import bgImage from "assets/images/fondo-sophos2.jpg";
import logoSolution from "assets/images/logo-sophos.png";
import { CardsComponentRotatory } from "components/CardsComponentRotatory";

const configs = {
  columnType: columnTypes.TWO.id,
  linkSection: {
    show: true,
    message: "si quieres mas información ingresa aquí",
    redirectURL: "https://www.sophos.com/es-es",
  },
  cardsTitle:
    "Conozca nuestro servicio de Managed Detection and Response (MDR) - Libere a su personal de TI y seguridad para que pueda centrarse en conseguir resultados de seguridad excepcionales",

  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "2rem",
      padding: "0px 2vw 50px 2vw",
      color: "#1e5ca9",
    },
  },
  cardsData: [
    {
      image: cardImage1,
      title: "ENDPOINT",
      text: "Proteja sus datos dondequiera que estén con una prevención, detección y respuesta superiores que detienen más amenazas más rápido. ",
      _text:
        "Endpoint Protection - Antivirus next-gen, Endpoint Detection and Response (EDR), Extended Detection and Response (XDR), Server Protection, Mobile Security",
    },
    {
      image: cardImage2,
      title: "FIREWALL",
      text: "Conecte sus redes de forma segura con nuestras herramientas de Network security para permitir una arquitectura ZTNA, contamos con herramientas tambien para redes hibridas",
      _text:
        "Firewall Next-Gen, Zero Trust Network Access (ZTNA), Network Detection and Response, SD-WAN, Switches de red, Puntos de acceso inahlambricos",
    },
    {
      image: cardImage3,
      title: "CLOUD",
      text: "Proteja cargas de trabajo, datos, aplicaciones y accesos en la nube frente a las amenazas avanzadas y vulnerabilidades más recientes. AWS, Azure, Google Cloud y Oracle.",
      _text:
        "Cloud Worload Protection, Gestión de la postura de seguridad en la nube (CSPM)”",
    },
    {
      image: cardImage4,
      title: "EMAIL",
      text: "Confie nuevamente en su bandeja de entrada con la seguridad del correo electrónico en la nube, que protege a sus usuarios y su información crítica frente al malware, el phishing y los intentos de suplantación de identidad.",
      _text: "Soluciones como: Email Advance, PhisThreat",
    },
  ],
};

const textoColor = "ECOSISTEMA DE CIBERSEGURIDAD ADAPTATIVO";
const textoContenido =
  "Con SOPHOS Tienes una solución de ciberseguridad para cada capa de seguridad.";

function Features() {
  return (
    <BaseLayout
      bgImage={bgImage}
      logoSolution={logoSolution}
      desc={""}
      cardsComponent={() => CardsComponentRotatory(configs)}
      imagenContenido={imagenContenido}
      colorTexto="#176ab5"
      textoContenido={textoContenido}
      textoColor={textoColor}
      ordenTitulo={true}
    />
  );
}

export default Features;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import vicariusLogo from "assets/images/logos/gray-logos/vicarius-logo.png";
import paperCutLogo from "assets/images/logos/gray-logos/papercut.png";
import hplogo from "assets/images/logos/gray-logos/HP.png";
import sophosLogo from "assets/images/logos/gray-logos/sophos-logo.jpg";
import awsLogo from "assets/images/logos/gray-logos/aws-logo.png";
import logoKpax from "assets/images/logos/gray-logos/logo-kpax.png";
import logoMIcrosoft from "assets/images/logos/gray-logos/microsoft.png";


function Information() {
  return (
    <MKBox component="section" py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">Nuestros Partners</MKTypography>
         
        </Grid>
      
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center" alignContent={"center"}>
        <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={hplogo} alt="HP" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={logoKpax} alt="Kpax" width="50%" opacity={0.6} />
            
          </Grid>
          <Grid item xs={6} md={4} lg={2} mt={2}>
            <MKBox component="img" src={vicariusLogo} alt="Vicarius" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} mt={1}>
            <MKBox component="img" src={paperCutLogo} alt="PaperCut" width="100%" opacity={0.6} />
          </Grid>
         
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={sophosLogo} alt="Sophos" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={logoMIcrosoft} alt="Office 365" width="100%" opacity={0.6} />
            
          </Grid>
          
         
         
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

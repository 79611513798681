/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sections components
import BaseLayout from "layouts/sections/components/BaseLayout";
import bgImage from "assets/images/fondo-serviciosimp.jpg";
import { CardsComponent } from "components/CardsComponent";
import { columnTypes } from "layouts/sections/page-sections/page-headers";

import logoSolution2 from "assets/images/plantman.png";
import cardImage1 from "assets/images/menor_costo.jpg";
import cardImage2 from "assets/images/mantenimiento_eficiente.jpg";
import cardImage3 from "assets/images/consumibles.jpg";
import cardImage4 from "assets/images/impresion_segura.jpg";
import cardImage5 from "assets/images/incremento_productividad.jpg";
import cardImage6 from "assets/images/supervision_remota.jpg";
import footerimage from "assets/images/brochure_impresion.jpg";

import "./index.css";

const description = "Servicios de impresión";
import imagenContenido from "assets/images/impresiones-imagec.jpg";
const textoContenido =
  "Optimiza tus procesos de impresión con nuestro Servicio de Gestión de Impresión (MPS), diseñado para ofrecerte eficiencia, control y ahorro en cada página. <br/>Confiar en nosotros es liberarte de las preocupaciones de gestión de impresión, permitiéndote enfocarte en lo que realmente importa";

const configs = {
  columnType: columnTypes.THREE.id,
  linkSection: {
    show: false,
  },
  sideImage: logoSolution2,
  styles: {
    titleStyle: {
      color: "black",
      fontWeight: "bold",
      fontSize: 30,
      maxWidth: 250,
    },
  },
  cardsData: [
    {
      image: cardImage1,
      title: "Menor costo de propiedad",
    },
    {
      image: cardImage3,
      title: "Consumibles Gestionados",
    },
    {
      image: cardImage4,
      title: "impresion Segura",
    },
    {
      image: cardImage2,
      title: "Mantenimiento eficiente de los equipos",
    },
    {
      image: cardImage5,
      title: "Incremento de la productividad",
    },
    {
      image: cardImage6,
      title: "Supervision remota de los equipos",
    },
  ],
};

const FooterImage = () => {
  return (
    <div>
      <img
        className="cardImage"
        src={footerimage}
        alt={`CfooterCardPrintingServices`}
      />
    </div>
  );
};

function Navbars() {
  return (
    <BaseLayout
      bgImage={bgImage}
      logoSolution={""}
      desc={description}
      cardsComponent={() => CardsComponent(configs)}
      lastSection={() => FooterImage()}
      imagenContenido={imagenContenido}
      textoContenido={textoContenido}
    />
  );
}

export default Navbars;

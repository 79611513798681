/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import bgImage from "assets/images/fondo-kpax.png";
import logoSolution from "assets/images/logo-kpax.jpg";
import BaseLayout from "layouts/sections/components/BaseLayout";
import imagenContenido from "assets/images/kpax-imagec.png";

import { columnTypes } from "layouts/sections/page-sections/page-headers";

import logoSolution2 from "assets/images/plantman.png";
import sideImageKpax from "assets/images/kpaxside.png";
import cardImage1 from "assets/images/finanzas.jpg";
import cardImage2 from "assets/images/alojamiento.jpg";
import cardImage3 from "assets/images/credibilidad.jpg";
import cardImage4 from "assets/images/proximidad.jpg";
import cardImage5 from "assets/images/disponibilidad.jpg";
import cardImage6 from "assets/images/integracion.jpg";
import footerimage from "assets/images/brochure_impresion.jpg";
import { useEffect } from "react";
import { useState } from "react";
import { CardsComponent } from "components/CardsComponent";

const configs = {
  columnType: columnTypes.THREE.id,
  linkSection: {
    show: true,
    message: "si quieres mas información ingresa aquí",
    redirectURL: "https://www.kpax-manage.com/",
  },
  sideImage: logoSolution2,
  cardsTitle: "PUNTOS CLAVE",

  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "30px",
      color: "#0d67a0",
      marginBottom: "50px",
    },
    titleStyle: {
      color: "black",
      fontWeight: "bold",
      fontSize: 30,
      maxWidth: 250,
    },
  },
  cardsData: [
    {
      image: cardImage1,
      title: "Finanzas",
      text: "Solo pagas por los datos que utilizas con Koins",
    },
    {
      image: cardImage3,
      title: "Alojamiento",
      text: "Disponible en la nube  oen versión local",
    },
    {
      image: cardImage4,
      title: "Credibilidad",
      text: "10 años de experiencia y 600.000 dispositivos gestionados",
    },
    {
      image: cardImage2,
      title: "Proximidad",
      text: "Con nuestros MVP, se beneficiará de un experto certificado cerca de usted",
    },
    {
      image: cardImage5,
      title: "Disponibilidad",
      text: "Garantice una mejor disponibilidad del servicio con Liberty",
    },
    {
      image: cardImage6,
      title: "Integración",
      text: "KPAX permite desarrollar “por diseño” conectores bidireccionales con aplicación de terceros",
    },
  ],
};

const configsIntermediate = {
  columnType: columnTypes.TWO.id,
  cardsTitle: "BENEFICIOS",
  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "30px",
      color: "#0d67a0",
    },
  },
  sideImage: sideImageKpax,
  cardsData: [
    {
      text: "Recopila alertas técnicas y códigos de error del dispositivo, lo que le permite brindar un servicio posventa proactivo y eficiente.",
    },
    {
      text: "Recupera información sobre el estado de los consumibles, lo que le permite automatizar la entrega y beneficiarse de una gestión óptima.",
    },
    {
      text: "Automatiza informes de sistemas de impresión en red independientemente de la marca.",
    },
    {
      text: "Produce cuadros de mando, indicadores o informes necesarios para analizar la situación.",
    },
  ],
};

const description = "Solución de gestión para dispositivos de impresión";
const textoContenido =
  "Kpax es nuestra herramienta de suministros de impresión gestionados, te permite realizar compras más seguras de suministros e insumos reduciendo costos";
const textoColor = "Manejas tu propio";
const textoNegro = "Parque de impresión?";

function Pagination() {
  return (
    <BaseLayout
      bgImage={bgImage}
      logoSolution={logoSolution}
      desc={description}
      textoContenido={textoContenido}
      colorTexto="#0d67a0"
      imagenContenido={imagenContenido}
      intermediateSection={() => CardsComponent(configsIntermediate)}
      cardsComponent={() => CardsComponent(configs)}
      textoColor={textoColor}
      textoNegro={textoNegro}
    />
  );
}

export default Pagination;

import ReactPlayer from "react-player/youtube";

export const YouTubeVideoComponent = ({ videoUrl }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "20px 0",
      }}
    >
      <ReactPlayer url={videoUrl} controls={true} width="80vw" height="80vh" />
    </div>
  );
};

import { columnTypes } from "layouts/sections/page-sections/page-headers";
import "./index.css";

export const CardsComponent = (configs) => {
  console.log(configs);
  const isTWO = configs?.columnType === columnTypes.TWO.id;
  return (
    <>
      <div style={configs?.styles?.cardsTitleStyle}> {configs?.cardsTitle}</div>
      <div className={isTWO && "containerCards"}>
        {isTWO && (
          <div className="logo-container">
            <img
              src={configs?.sideImage}
              alt="Logo"
              className="logo-image"
              style={configs?.styles?.sideImageStyle}
            />
          </div>
        )}

        <div
          className="cards-container"
          style={columnTypes[configs?.columnType]?.styles}
        >
          {configs?.cardsData.map((card, index) => (
            <div
              key={index}
              className="card"
              style={columnTypes[configs?.columnType]?.cardsStyles}
            >
              {card?.image && (
                <img
                  style={
                    ![null, undefined].includes(configs?.styles?.logoStyles)
                      ? configs?.styles?.logoStyles
                      : columnTypes[configs?.columnType]?.logoStyles
                  }
                  className="cardImage"
                  src={card?.image}
                  alt={`Card ${index + 1}`}
                />
              )}
              {card?.title && (
                <h3 className="cardTitle" style={configs?.styles?.titleStyle}>
                  {card?.title}
                </h3>
              )}
              {card?.text && (
                <p className="cardTText" style={configs?.styles?.textStyles}>
                  {card?.text}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        {configs?.linkSection?.show && (
          <>
            <a target="_blank" href={configs?.linkSection?.redirectURL}>
              {configs?.linkSection?.message}
            </a>
          </>
        )}
      </div>
    </>
  );
};

import { columnTypes } from "layouts/sections/page-sections/page-headers";
import "./index.css";
import { Grid } from "@mui/material";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

import bgFront from "assets/images/rotating-card-bg-back.jpg";
import bgBack from "assets/images/rotating-card-bg-back.jpg";

const cardStyles = {
  width: "35vw", // Define un ancho fijo para todas las tarjetas
  minWidth: "350px",
  height: "max-content",
  margin: "70px 0px",
  alignItems: "start",
};

export const CardsComponentRotatory = (configs) => {
  console.log(configs);
  const isTWO = configs?.columnType === columnTypes.TWO.id;
  return (
    <>
      <div style={configs?.styles?.cardsTitleStyle}> {configs?.cardsTitle}</div>
      <div className={isTWO && "containerCardsRotatory"}>
        <div
          className="cards-container"
          style={{
            ...columnTypes[configs?.columnType]?.styles,
            width: "100% !important",
          }}
        >
          {configs?.cardsData.map((card, index) => (
            <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
              <div style={cardStyles} key={card?.image}>
                {" "}
                <RotatingCard>
                  <RotatingCardFront
                    image={card?.image}
                    icon="touch_app"
                    title={card?.title}
                    description={card?.text}
                  />
                  <RotatingCardBack
                    image={card?.image}
                    title={card?.title}
                    description={card?._text}
                  />
                </RotatingCard>
              </div>
            </Grid>
          ))}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100vw",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        {configs?.linkSection?.show && (
          <>
            <a target="_blank" href={configs?.linkSection?.redirectURL}>
              {configs?.linkSection?.message}
            </a>
          </>
        )}
      </div>
    </>
  );
};

import React, { useState } from "react";
import { Modal, Box, Button } from "@mui/material";
import Quiz from "react-quiz-component";
import { Height } from "@mui/icons-material";
import "./QuizComponent.css";

const quiz = {
  appLocale: {
    landingHeaderText: "<questionLength> Preguntas",
    question: "Pregunta",
    startQuizBtn: "Comenzar Cuestionario",
    resultFilterAll: "Todo",
    resultFilterCorrect: "Correcto",
    resultFilterIncorrect: "Incorrecto",
    nextQuestionBtn: "Siguiente",
    resultPageHeaderText:
      "Has completado el cuestionario. Obtuiste <correctIndexLength> de <questionLength> preguntas correctas.",
    singleSelectionTagText: null,
    pickNumberOfSelection: "Selecciona <numberOfSelection>",
    resultPagePoint: "Obtuviste <correctPoints> de <totalPoints> puntos",
    marksOfQuestion: "",
  },
  quizTitle: "Test de madurez de ciberseguridad",
  quizSynopsis: "Evaluación de la postura de ciberseguridad de tu empresa",
  questions: [
    {
      question:
        "¿Existe un proceso claro para reportar incidentes de seguridad ?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se utilizan firewalls o sistemas de detección de intrusiones para proteger la red de la empresa?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se realizan análisis regulares de vulnerabilidades en la infraestructura de red?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se realizan análisis regulares de vulnerabilidades en los activos?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se requiere autenticación de múltiples factores para acceder a sistemas críticos?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se revocan de manera oportuna los privilegios de acceso de los empleados que dejan la empresa o cambian de roles?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se aplican parches y actualizaciones de seguridad de manera regular en todos los dispositivos y sistemas?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se utilizan soluciones de seguridad de endpoints, como antivirus y antimalware, en todos los dispositivos de la empresa?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Se proporciona formación regular a los empleados sobre la importancia de proteger los datos y la privacidad de los clientes?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
    {
      question:
        "¿Realiza capacitaciones y campañas de concientización a sus usuarios?",
      questionType: "text",
      answerSelectionType: "single",
      answers: ["Si", "No"],
      correctAnswer: "1",
      point: "1",
    },
  ],
};

const customResultPage = (obj) => {
  console.log(obj);
  return (
    <div>
      <h3>Resultados de la madurez en ciberseguridad de tu empresa</h3>
      <p style={{ marginTop: 30 }}>
        Tu puntaje: {obj.numberOfCorrectAnswers} de {obj.numberOfQuestions}
      </p>
      <p>
        Porcentaje: {(obj.numberOfCorrectAnswers * 100) / obj.numberOfQuestions}
        %
      </p>
      <p>¡Gracias por completar el cuestionario!</p>

      <p style={{ marginTop: 30 }}>
        Para mejorar en las áreas identificadas, no dude en contactarnos y asi
        fortalecer la ciberseguridad en su compañía.
      </p>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "450px",
  bgcolor: "background.paper",
  border: "1px solid transparent",
  boxShadow: 24,
  p: 4,
  height: "80vh",
  overflow: "auto",
  borderRadius: "8px",
};

const appLocale = {
  landingHeaderText: "<questionLength> Preguntas",
  question: "Pregunta",
  startQuizBtn: "Comenzar Cuestionario",
  resultFilterAll: "Todo",
  resultFilterCorrect: "Correcto",
  resultFilterIncorrect: "Incorrecto",
  nextQuestionBtn: "Siguiente",
  resultPageHeaderText:
    "Has completado el cuestionario. Obtuiste <correctIndexLength> de <questionLength> preguntas correctas.",
  singleSelectionTagText: "",
  pickNumberOfSelection: "Selecciona <numberOfSelection>",
  resultPagePoint: "Obtuviste <correctPoints> de <totalPoints> puntos",
};

const QuizModal = ({ open, handleClose }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);

  const handleAnswerClick = (answer) => {
    // Pasa a la siguiente pregunta al responder
    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
  };

  const handleQuizComplete = () => {
    // Muestra las respuestas al completar todas las preguntas
    setShowAnswers(true);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="quiz-modal-title"
      aria-describedby="quiz-modal-description"
    >
      <Box sx={style}>
        <Quiz
          quiz={quiz}
          appLocale={appLocale}
          showDefaultResult={false}
          customResultPage={customResultPage}
          revealAnswerOnSubmit={false}
          showInstantFeedback={false}
        />
        <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
          <div style={{ color: "white" }}>Salir</div>
        </Button>
      </Box>
    </Modal>
  );
};

export default QuizModal;

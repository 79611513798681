/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/sobrenosotros.jpg";

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid
            item
            sx={12}
            md={6}
            sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
          >
            <MKTypography variant="h4">SOBRE NOSOTROS</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              <p>
                Somos una empresa caleña, con experiencia en el mercado
                corporativo desde el 2008. Nos especializamos en soluciones
                tecnológicas y de ciberseguridad, brindando servicios y
                productos innovadores que ayudan a nuestros clientes a alcanzar
                sus objetivos de manera eficiente y segura.
              </p>
              <br />
              <p>
                En Kinitech, cultivamos relaciones sólidas y de
                confianza con cada uno de nuestros clientes. Nuestro compromiso
                va más allá de la transacción; estamos aquí para apoyarlos en
                cada paso del camino, ayudándolos a alcanzar sus metas y
                afrontar los desafíos del futuro con confianza.
              </p>
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;

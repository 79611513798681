import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "./index.css";

const testText =
  "Nuestros servicios ofrecen a los clientes una protección proactiva y una comprensión integral de sus vulnerabilidades digitales, asegurando la seguridad de sus activos en línea y proporcionando tranquilidad en un entorno cibernético cada vez más complejo.";

export default function ImageTextComponent({
  ordenFlex,
  textContenido,
  tituloCarta,
  imagenContenido,
  listaTexto,
  boldTextContenido,
  colorTituloCarta,
}) {
  console.log(boldTextContenido);
  return (
    <MKBox
      className="flex-contenidoITC"
      flexDirection={{ xs: "column", md: ordenFlex ? "row" : "row-reverse" }}
    >
      <div className={tituloCarta ? "box-contenidosmall" : "box-contenidoITC"}>
        {tituloCarta && (
          <div className="box-tituloc">
            <MKTypography
              className="text-tituloc"
              sx={{ color: colorTituloCarta }}
            >
              {tituloCarta}
            </MKTypography>
          </div>
        )}
        <div className="box-textoITC">
          <MKTypography
            className={
              tituloCarta
                ? listaTexto
                  ? "texto-boxclistas"
                  : "texto-boxc2"
                : "texto-boxcITC"
            }
            fontWeight={boldTextContenido ? "bold" : "light"}
            dangerouslySetInnerHTML={{ __html: textContenido }}
          >
            {/* {textoContenido} */}
          </MKTypography>
          {listaTexto && (
            <MKTypography
              className="box-listatexto texto-boxclistas"
              fontWeight={boldTextContenido ? "bold" : "light"}
              dangerouslySetInnerHTML={{ __html: listaTexto }}
            />
          )}
        </div>
      </div>
      <div className="box-contenido2">
        <img src={imagenContenido} className="imagenLogo"></img>
      </div>
    </MKBox>
  );
}

import BaseLayout from "layouts/sections/components/BaseLayout";
import CenteredFooter from "examples/Footers/CenteredFooter";
import MKBox from "components/MKBox";
//imagenes contenido cartas
import bgImage from "assets/images/fondo-desarrollo.jpg";
import image1 from "assets/images/imagentextosoft-1.jpg";
import image2 from "assets/images/imagentextosoft-2.png";
import image3 from "assets/images/imagentextosoft-3.jpg";
import image4 from "assets/images/imagentextosoft-4.jpg";
import image5 from "assets/images/imagentextosoft-5.jpg";
//Imagenes flex de nuestras herramientas
import herra1 from "assets/images/react-logo.png";
import herra2 from "assets/images/js-logo.png";
import herra3 from "assets/images/python-logo.png";
import herra4 from "assets/images/django-logo.png";
import herra5 from "assets/images/postman-logo.png";
import herra6 from "assets/images/aws-logo.png";
import imagenMeto from "assets/images/metodologia.png";
// import imagenMeto from "assets/images/metodologia1.jpg"
import ImageTextComponent from "components/ImageTextComponent";

import "./index.css";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

const description = "Desarrollo de software";

const ListaTexto = `
          <ul>
            <li><b>Escalabilidad:</b> Permite escalar recursos de manera rápida y eficiente según las necesidades del negocio.</li>
            <li><b>Costos Reducidos:</b> Reducir significativamente los costos de infraestructura.</li>
            <li><b>Flexibilidad y Agilidad:</b> Adaptación rápida a los cambios del mercado y a las necesidades del negocio.</li>
            <li><b>Acceso Remoto:</b> Permite a los empleados acceder desde cualquier lugar y en cualquier momento.</li>
            <li><b>Seguridad Mejorada:</b> Altos estándares de seguridad y cumplimiento normativo.</li>
            <li><b>Respaldo y Recuperación de Datos:</b> Lo que garantiza la disponibilidad continua de la información empresarial</li>
            <li><b>Innovación Continua:</b> Cuenta con actualizaciones y nuevas funcionalidades de forma regular</li>
          </ul>
          `;
const datosCartas = [
  {
    ordenFlex: false,
    textContenido:
      "Integra la tecnología digital en todas las áreas de tu empresa para llevar tu empresa hacia el futuro. En nuestro servicio de desarrollo de software, ofrecemos soluciones a medida que no solo modernizan tu operación, sino que también desbloquean un potencial ilimitado para el crecimiento.",
    imagenContenido: image1,
    titulo: "Transformación digital",
  },
  {
    ordenFlex: true,
    textContenido:
      "Libera el potencial de tu equipo al eliminar tareas repetitivas y propensas a errores, permiteles enfocarse en actividades estratégicas que impulsen el crecimiento y la innovación. Con nuestra tecnología de vanguardia, simplificamos y optimizamos tus operaciones para que puedas alcanzar nuevos niveles de productividad y éxito empresarial.",
    imagenContenido: image2,
    titulo: "Automatización de procesos",
  },
  {
    ordenFlex: false,
    textContenido:
      "Nuestro equipo experto te guiará en cada etapa del proceso para crear soluciones digitales que no solo sean visualmente atractivas, sino también funcionales y escalables. Ya sea que necesites una plataforma de comercio electrónico, un sistema de gestión empresarial o una aplicación interactiva, estamos aquí para convertir tus ideas en realidad.",
    imagenContenido: image3,
    titulo: "Desarrollo de aplicativos web",
  },
  {
    ordenFlex: false,
    textContenido:
      "Garantizamos la calidad de cada proyecto mediante nuestro riguroso proceso de Control de Calidad (QA), donde cada detalle es examinado con precisión. Además, para proyectos de mayor complejidad, ofrecemos pruebas automatizadas, asegurando así un estándar de excelencia y un rendimiento óptimo en cada entrega.",
    imagenContenido: image5,
    titulo: "Pruebas de Calidad (QA)",
  },
];

const herramientas = [herra1, herra2, herra3, herra4, herra5, herra6];

const EmptyFunction = () => {
  return <div></div>;
};

function DesarrolloSoftware() {
  return (
    <>
      <MKBox bgColor="white" className="box-parentD">
        <BaseLayout
          bgImage={bgImage}
          logoSolution={""}
          desc={description}
          cardsComponent={EmptyFunction}
          soloHeader={true}
        />
        <div className="flex-desoft">
          <div className="box-textotitulo">
            <p className="texto-titulonegro">
              Desarrollo de soluciones de software{" "}
              <span className="texto-titulocolor">a la medida</span> para tu{" "}
              <span className="texto-titulocolor">empresa</span>
            </p>
          </div>
          <div className="box-titulo2">
            <p className="texto-titulo2">Nuestras soluciones</p>
          </div>
          {datosCartas.map((datos, id) => (
            <ImageTextComponent
              id={id}
              ordenFlex={datos.ordenFlex}
              textContenido={datos.textContenido}
              imagenContenido={datos.imagenContenido}
              tituloCarta={datos.titulo}
              boldTextContenido={false}
              listaTexto={datos.listaTexto}
              colorTituloCarta="#3799A6"
            />
          ))}

          <div className="box-titulo2">
            <p className="texto-titulo2">Utilizamos herramientas de punta</p>
          </div>
          <div className="flex-herramientas">
            {herramientas.map((herramienta, id) => (
              <div className="box-imgherramienta" id={id}>
                <img src={herramienta} className="img-herramienta"></img>
              </div>
            ))}
          </div>
        </div>
      </MKBox>
      <MKBox mt="auto">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default DesarrolloSoftware;

import BaseLayout from "layouts/sections/components/BaseLayout";
import bgImage from "assets/images/fondo-servciber.jpg";
import ImageTextComponent from "components/ImageTextComponent";
import imagenPrincipal from "assets/images/imagentexto-0.jpg";
import imagen1 from "assets/images/imagentexto-1.jpg";
import imagen2 from "assets/images/imagentexto-2.jpg";
import imagen3 from "assets/images/imagentexto-3.jpg";
import imagen4 from "assets/images/imagentexto-4.jpg";
import imagen5 from "assets/images/imagentexto-5.png";
import MKBox from "components/MKBox";
import "./index.css";
import CenteredFooter from "examples/Footers/CenteredFooter";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

const description = " Servicios Ofensivos de Ciberseguridad";
const textContenido =
  "Nuestros servicios ofrecen a los clientes una protección proactiva y una comprensión integral de sus vulnerabilidades digitales, asegurando la seguridad de sus activos en línea y proporcionando tranquilidad en un entorno cibernético cada vez más complejo.";

const ListaTexto = `
            <ul>
                <li>E.H a infraestructura</li><li>E.H portales Web</li>
                <li>Pruebas de penetración aplicaciones moviles</li>
                <li>Auditoria WIFI</li><li>Auditoria en la nube</li>
            </ul>
            `;
const EmptyFunction = () => {
  return <></>;
};

const datosCartas = [
  {
    ordenFlex: false,
    textContenido:
      "Adelantate a un ataque realizando análisis ofensivo a tu empresa con nuestros hacker éticos. Utilizamos la metodologia OWASP. <br>Nuestros servicios son: ",
    imagenContenido: imagen1,
    tituloCarta: "Hacking Ético",
    listaTexto: ListaTexto,
  },
  {
    ordenFlex: true,
    textContenido:
      "Nuestro servicio de pentesting ofrece una evaluación exhaustiva de la seguridad de tu sistema.<br><br>Utilizamos técnicas avanzadas para simular ataques reales y proporcionamos recomendaciones específicas para mejorar la seguridad. ",
    imagenContenido: imagen2,
    tituloCarta: "Pentesting",
  },
  {
    ordenFlex: false,
    textContenido:
      "Ofrecen una evaluación completa de la seguridad de tus instalaciones, identificando puntos débiles y recomendando medidas para fortalecer la protección.<br><br>Utilizamos escenarios realistas para simular intrusiones y evaluamos la respuesta de tu equipo de seguridad. ",
    imagenContenido: imagen3,
    tituloCarta: "Pruebas de intrusiones fisicas",
  },
  {
    ordenFlex: true,
    textContenido:
      "Realizamos campañas de correos simulados para evaluar la resiliencia de tu equipo. Identificamos qué usuarios podrían caer en trampas y ofrecemos módulos de capacitación personalizados para fortalecer su conciencia y proteger tus activos digitales",
    imagenContenido: imagen4,
    tituloCarta: "Ingenieria Social",
  },
  {
    ordenFlex: false,
    textContenido:
      "Utilizamos herramientas avanzadas y técnicas de evaluación exhaustiva para descubrir vulnerabilidades en sistemas, redes y aplicaciones. Nuestro objetivo es proporcionarte una visión clara de los riesgos de seguridad y recomendaciones precisas para mitigarlos.",
    imagenContenido: imagen5,
    tituloCarta: "Análisis de Vulnerabilidades",
  },
];

function ServCiberseguridad() {
  return (
    <>
      <MKBox bgColor="white" className="box-parent">
        <BaseLayout
          bgImage={bgImage}
          logoSolution={""}
          desc={description}
          cardsComponent={EmptyFunction}
          soloHeader={true}
        />
        <ImageTextComponent
          ordenFlex={true}
          textContenido={textContenido}
          imagenContenido={imagenPrincipal}
          boldTextContenido={true}
        />
        <div className="flex-ciberseg">
          <div className="box-titulo">
            <p className="texto-titulo">Servicios de Ciberseguridad</p>
          </div>
          {datosCartas.map((datos, id) => (
            <ImageTextComponent
              id={id}
              ordenFlex={datos.ordenFlex}
              textContenido={datos.textContenido}
              imagenContenido={datos.imagenContenido}
              tituloCarta={datos.tituloCarta}
              listaTexto={datos.listaTexto}
              boldTextContenido={true}
              colorTituloCarta="#1e5ca9"
            />
          ))}
        </div>
      </MKBox>
      <MKBox mt="auto">
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ServCiberseguridad;

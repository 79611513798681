import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
} from "@mui/material";
import IconButton from "assets/theme/components/iconButton";
import axios from "axios";

const steps = ["Código de Verificación", "Estado del Pedido"]; // Two steps
const stepsStatuses = ["RECIBIDO", "ALISTANDO", "ENVIADO", "ENTREGADO"];
const stepsStatusesMap = {
  RECIBIDO: { value: 0 },
  ALISTANDO: { value: 1 },
  ENVIADO: { value: 2 },
  ENTREGADO: { value: 3 },
};

const notFoundMessage = "No encontrado";
const regex = /^KNOT\d+$/;

const TrackOrderModal = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [orderStatusData, setOrderStatusData] = useState(null);

  const handleNext = async () => {
    if (activeStep === 0 && !verificationCode) return;

    if (activeStep === 0 && verificationCode) {
      setLoading(true);
      setActiveStep(activeStep + 1);

      try {
        const response = await axios.get(
          `https://logisticsapp-1ae393596967.herokuapp.com/api/requests/${Number(
            verificationCode.replace("KNOT", "")
          )}/`
        );
        console.log(response); // Reemplaza con tu endpoint real
        setOrderStatus(`El estado de su pedido (KNOT${response.data.id}) es:`);
        setOrderStatusData(response?.data);
      } catch (error) {
        console.error("Error obteniendo estado del pedido:", error);
        setOrderStatus(notFoundMessage);
        setOrderStatusData(null);
        // Maneja el error, por ejemplo mostrando un mensaje al usuario
      } finally {
        setLoading(false);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };
  console.log(activeStep);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 4,
          bgcolor: "background.paper",
          mx: "auto",
          my: "20%",
          width: 400,
          borderRadius: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Rastrea tu pedido
        </Typography>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ mt: 2 }}>
          {activeStep === 0 && (
            <TextField
              fullWidth
              label="Orden de compra"
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          )}
          {activeStep === 1 && (
            <Box textAlign="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <div>
                  {" "}
                  <Typography variant="h6">{orderStatus}</Typography>
                  {orderStatusData?.status}
                  {orderStatus !== notFoundMessage && (
                    <div style={{ marginTop: 20 }}>
                      <Stepper
                        activeStep={
                          stepsStatusesMap[orderStatusData?.status]?.value
                        }
                        alternativeLabel
                      >
                        {stepsStatuses.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                  )}
                </div>
              )}
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Atrás
          </Button>
          {activeStep !== steps.length - 1 && (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={!regex.test(verificationCode) || !verificationCode}
            >
              <div style={{ color: "white" }}>
                {activeStep === steps.length - 1 ? "Cerrar" : "Siguiente"}
              </div>
            </Button>
          )}

          <Button onClick={handleClose}>Cerrar</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TrackOrderModal;

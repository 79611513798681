import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MKTypography from "components/MKTypography";
import bgFront from "assets/images/rotating-card-bg-back.jpg";
import bgBack from "assets/images/rotating-card-bg-back.jpg";
import QuizModal from "components/QuizComponent";

function Information() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <MKBox component="section" py={6} my={6}>
        <div
          style={{
            display: "flex",
            width: "95vw",
            justifyContent: "center",
            padding: "20px 5vw 20px 5vw",
          }}
        >
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            Descubre el nivel de seguridad de tu empresa con nuestro test
            especializado
          </MKTypography>
        </div>
        <Container>
          <Grid
            container
            item
            xs={11}
            spacing={3}
            alignItems="center"
            sx={{ mx: "auto" }}
          >
            <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgFront}
                  icon="touch_app"
                  title={
                    <>
                      Realiza nuestro test
                      <br />
                      de Ciberseguridad
                    </>
                  }
                  description="Evaluación de la postura de seguridad cibernética de tu empresa"
                />
                <RotatingCardBack
                  image={bgBack}
                  title="¡Empieza ahora y fortalece la seguridad de tu empresa!"
                  action={{
                    type: "button",
                    func: handleOpen,
                    label: "Realizar test",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="devices"
                    title="Conciencia de Seguridad Personalizada"
                    description="Proporciona una comprensión clara de dónde se encuentran tu empresa en términos de seguridad cibernética."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="search"
                    title="Identificación de Vulnerabilidades Potenciales"
                    description="Identifica posibles vulnerabilidades en su infraestructura y prácticas de seguridad"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="help"
                    title="Mayor Tranquilidad y Confianza"
                    description="Siéntase más seguros en cuanto a la protección de sus datos y sistemas contra amenazas cibernéticas."
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon="content_copy"
                    title="Cumplimiento Normativo y Regulatorio "
                    description="Garantice un mayor cumplimiento de las normativas y regulaciones de ciberseguridad relevantes para su compañía."
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <QuizModal open={open} handleClose={handleClose} />
    </>
  );
}

export default Information;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import { columnTypes } from "layouts/sections/page-sections/page-headers";

import { CardsComponent } from "components/CardsComponent";
import logoSolution2 from "assets/images/plantman.png";
import cardImage1 from "assets/images/ns_balanza.png";
import cardImage2 from "assets/images/ns_personas.png";
import cardImage3 from "assets/images/ns_check.png";
import cardImage4 from "assets/images/ns_arrows.png";
import cardImage5 from "assets/images/ns_love.png";
import cardImage6 from "assets/images/ns_christ.png";

function Information() {
  const configs = {
    columnType: columnTypes.THREE.id,
    linkSection: {
      show: false,
    },
    sideImage: logoSolution2,
    styles: {
      titleStyle: {
        color: "black",
        fontWeight: "bold",
        fontSize: 30,
        maxWidth: 250,
      },
    },
    cardsData: [
      {
        image: cardImage1,
        title: "Integridad",
        text: "Construimos integridad  a través de la transparencia, el compromiso con la ética y la coherencia entre lo que decimos y hacemos",
      },
      {
        image: cardImage2,
        title: "Trabajo en equipo",
        text: "cada voz cuenta y cada contribución es valorada, fomentando la colaboración, la creatividad y el logro de metas compartidas",
      },
      {
        image: cardImage3,
        title: "Compromiso con el cliente",
        text: "Nos esforzamos por comprender sus necesidades y superar sus expectativas.",
      },
      {
        image: cardImage4,
        title: "Versatilidad",
        text: "Enfrentamos cualquier desafío con creatividad y eficacia, adaptándonos ágilmente a las necesidades cambiantes del mercado y de nuestros clientes.",
      },
      {
        image: cardImage5,
        title: "Pasión",
        text: "Nos apasiona lo que hacemos y nos esforzamos por contagiar esa pasión en cada tarea",
      },
      {
        image: cardImage6,
        title: "Gratitud",
        text: "Apreciamos y reconocemos el esfuerzo y contribución de cada miembro de nuestro equipo.",
      },
    ],
  };

  return (
    <MKBox component="section" py={12}>
      <Container>{CardsComponent(configs)}</Container>
    </MKBox>
  );
}

export default Information;

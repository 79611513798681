/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Kit 2 React components
import MKBox from "components/MKBox";


// Routes
import MainComponent from "components/MainComponent";
import DefaultFooter from "examples/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

function BaseLayout({
  bgImage,
  logoSolution,
  desc,
  cardsComponent,
  textoContenido,
  colorTexto,
  imagenContenido,
  ordenFlex,
  textoColor,
  textoNegro,
  ordenTitulo,
  soloHeader,
  lastSection = () => {},
  intermediateSection = () => {},
}) {
  return (
    <>
      <MainComponent
        bgImage={bgImage}
        logoSolution={logoSolution}
        desc={desc}
        cardsComponent={cardsComponent}
        textoContenido={textoContenido}
        colorTexto={colorTexto}
        imagenContenido={imagenContenido}
        ordenFlex={ordenFlex}
        textoColor={textoColor}
        textoNegro={textoNegro}
        ordenTitulo={ordenTitulo}
        lastSection={lastSection}
        soloHeader={soloHeader}
        intermediateSection={intermediateSection}
      />
      {!soloHeader && (
        <MKBox mt="auto">
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      )}
    </>
  );
}

// Typechecking props for the BaseLayout
BaseLayout.propTypes = {
  breadcrumb: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BaseLayout;

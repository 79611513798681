/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import MainComponent from "components/MainComponent";

// import bgImage from "assets/images/fondo-vrx.jpg";
import bgImage from "assets/images/fondo-vrx1.jpg";
import logoSolution from "assets/images/logo-vrx.png";
import logoSolution2 from "assets/images/tableto_vrx.png";
import cardImage1 from "assets/images/tuerca_computador.jpg";
import cardImage2 from "assets/images/tuerca_reloj.jpg";
import cardImage3 from "assets/images/escudo_gris.jpg";
import cardImage4 from "assets/images/cuadrado_abajo.jpg";
import cardImage6 from "assets/images/ojitovrxx.png";
import cardImage7 from "assets/images/compudb.jpg";
import cardImage8 from "assets/images/escudovrx.jpg";
import BaseLayout from "layouts/sections/components/BaseLayout";
import { CardsComponent } from "components/CardsComponent";

import imagenContenido from "assets/images/vrx-imagec.jpg";
import { YouTubeVideoComponent } from "components/YoutubeVideoComponent";
import { Height } from "@mui/icons-material";

const description = "Herramienta preventiva de gestion de vulnerabilidades";
const textoContenido =
  "valuación continua de vulnerabilidades. VRX simplifica la gestión de vulnerabilidades tradicional para que pueda identificar violaciones de seguridad y eliminar amenazas antes de ser explotadas";
const textoColor = "Solucion totalmente integrada de";
const textoNegro = "Reducción de riesgo";

export const columnTypes = {
  TWO: {
    id: "TWO",
    cardsStyles: {},
    styles: { gridTemplateColumns: "1fr 1fr", width: "90%", zoom: 0.9 },
  },
  THREE: {
    id: "THREE",
    logoStyles: { width: "150px" },
    cardsStyles: {},
    styles: {
      gridTemplateColumns: "1fr 1fr 1fr",
      zoom: 0.7,
      alignItems: "flex-start",
    },
  },
};
const configs = {
  columnType: columnTypes.TWO.id,

  sideImage: logoSolution2,
  cardsTitle: "NUESTRA SOLUCIÓN",
  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      margin: "0px auto",
      fontSize: "2.2rem",
      color: "#5e17eb",
      marginBottom: 20,
    },
    sideImageStyle: { borderRadius: 8, marginLeft: 20 },
    titleStyle: { color: "black" },
  },
  cardsData: [
    {
      image: cardImage1,
      title: "Visibilidad total de los activos en un solo lugar",
      text: "Tome el control de sus activos digitales con las funciones integrales de vRx",
    },
    {
      image: cardImage2,
      title: "Priorizacion contextual en tiempo real",
      text: "Céntrese en las vulnerabilidades que tienen una probabilidad real de ser explotadas",
    },
    {
      image: cardImage3,
      title: "Cerrar las brechas de seguridad",
      text: "La protección sin parche de VRX reduce el riesgo de violación de la seguridad, incluso si no hay parche disponible",
    },
    {
      image: cardImage4,
      title: "Automatiza su reducción de riesgos",
      text: "Con VRX puedes actualizar activos individuales y en grupos, implementar parches, configurar scripts y generar reportes de forma automatizada",
    },
  ],
};

const configsUserExperience = {
  columnType: columnTypes.THREE.id,
  linkSection: {
    show: true,
    message: "si quieres mas información ingresa aquí",
    redirectURL: "https://www.vicarius.io/",
  },
  cardsTitle: "EXPERIENCIA DE USUARIO",
  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "2rem",
      margin: "0px auto",
      color: "#5e17eb",
      marginBottom: 20,
    },

    cardsStyles: { width: "400px" },
    titleStyle: { color: "black" },
    textStyles: { fontSize: "2rem" },
    logoStyles: {
      width: "400px",
      height: "400px",
      marginBottom: "1vh",
      borderRadius: 10,
    },
  },
  cardsData: [
    {
      text: "Vive una experienca de usuario satisfactoria, VRX se actualiza cada 3 meses con el feedback que nos dan los usuarios. Con Kinitech cuentas con capacitaciones en cada actualización de versión.",
    },
    {
      text: "Contamos con 3 formas de soporte tecnico Documentacion en 3 idiomas epañol, ingles y portugues. Chat en linea con personal especializado con un SLA de 5 min. 24/7. Tickets a especialistas 8/5",
    },
    {
      text: "VSOCIETY_ Comunidad Cibernetica abierta e independiente, centrada en el usuario, creada con el fin de lograr que la investigación de vulnerabilidades sea compartible.",
    },
  ],
};

const configsIntermediate = {
  columnType: columnTypes.THREE.id,

  cardsTitle: "¿COMO LO HACEMOS?",
  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      margin: "0px auto 20px auto",
      fontSize: "2rem",
      color: "#5e17eb",
    },

    cardsStyles: { width: "400px" },
    titleStyle: { color: "black", fontSize: 40 },
    textStyles: { fontSize: "2rem" },
    logoStyles: {
      width: "250px",
      height: "250px",
      marginBottom: "1vh",
      borderRadius: 10,
    },
  },
  cardsData: [
    {
      image: cardImage6,
      title: "Analiza",
      text: "Aplicaciones, Sistemas operativos, Puertos logicos y fisicos",
    },
    {
      image: cardImage7,
      title: "Prioriza",
      text: "Con un motor de IA contextualizado prioriza el riesgo en: Crítico, Alto, Medio, Bajo",
    },
    {
      image: cardImage8,
      title: "Mitiga",
      text: "Parcheo, Parche virtual, Scripts",
    },
  ],
};

const videoConfigs = {
  videoUrl: "https://www.youtube.com/watch?v=ic2jR0AhWpo",
};
function PageHeaders() {
  return (
    <>
      <BaseLayout
        bgImage={bgImage}
        logoSolution={logoSolution}
        desc={description}
        cardsComponent={() => {
          return (
            <div style={{ display: "flex", flexDirection: "column" }}>
              {CardsComponent(configs)}
              {CardsComponent(configsUserExperience)}
            </div>
          );
        }}
        textoContenido={textoContenido}
        imagenContenido={imagenContenido}
        colorTexto={"#5e17eb"}
        textoColor={textoColor}
        textoNegro={textoNegro}
        lastSection={() => YouTubeVideoComponent(videoConfigs)}
        intermediateSection={() => CardsComponent(configsIntermediate)}
      />
    </>
  );
}

export default PageHeaders;

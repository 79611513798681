import BaseLayout from "layouts/sections/components/BaseLayout";
import { CardsComponent } from "components/CardsComponent";
import { columnTypes } from "layouts/sections/page-sections/page-headers";

import cardImage1 from "assets/images/maor_rentabilidad.jpg";
import cardImage2 from "assets/images/calidad_certificada.jpg";
import cardImage3 from "assets/images/seguridad.jpg";
import cardImage4 from "assets/images/ambiente_saludable.jpg";
import cardImage5 from "assets/images/impresora.jpg";
import cardImage6 from "assets/images/manos_mundo.jpg";

const configs = {
  columnType: columnTypes.THREE.id,
  linkSection: {
    show: true,
    message: "si quieres mas información ingresa aquí",
    redirectURL: "https://www.hp.com/co-es/shop/",
  },
  sideImage: null,
  cardsTitle: "¿POR QUÉ UTILIZAR TONER ORIGINALES?",

  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "2rem",
      color: "rgb(4, 77, 149)",
      padding: "10px",
      margin: "0px 0px 70px 2rem",
    },
    titleStyle: {
      color: "#044D95",
      fontWeight: "bold",
      fontSize: 30,
    },
  },

  cardsData: [
    {
      image: cardImage1,
      title: "Mayor rentabilidad",
      text: "Al consumir cartuchos originales estas seguro de que el rendimiento es 100% real",
    },
    {
      image: cardImage3,
      title: "Calidad Certificada",
      text: "Tenemos procesos los cuales certifican la calidad de nuestros productos",
    },
    {
      image: cardImage4,
      title: "Seguridad en la red",
      text: "Los chips de un toner original te protegen de un ataque malicioso a tu red. ",
    },
    {
      image: cardImage2,
      title: "Ambiente saludable",
      text: "Los toner originales no botan polvillo, lo cual es tóxico con mayor razón en lugares cerrados ",
    },
    {
      image: cardImage5,
      title: "Mayor rentabilidad maquinas",
      text: "No bota polvillo de toner lo cual protege la impresora",
    },
    {
      image: cardImage6,
      title: "Tóner sostenibles",
      text: "Contamos con un sistema de recolección de cartuchos vacíos para reutilizarlos. HP produce tóner con plástico reutilizable",
    },
  ],
};

const videoConfigs = {
  videoUrl: "https://www.youtube.com/watch?v=BaF7-bOifwY",
};

import bgImage from "assets/images/fondo-consumibles.jpg";
import imagenContenido from "assets/images/consumibles-imagec.png";
import { YouTubeVideoComponent } from "components/YoutubeVideoComponent";
import { Margin } from "@mui/icons-material";

const description = "Consumibles originales";
const textoContenido =
  "Explora nuestro amplio catálogo de toner originales, respaldados por la garantía y calidad de HP, somos tu canal autorizado de confianza para todas tus necesidades de impresión";
const textoNegro = "CANALES AUTORIZADOS DE HEWLETT PACKARD";

function Consumibles() {
  return (
    <BaseLayout
      bgImage={bgImage}
      logoSolution={""}
      desc={description}
      cardsComponent={() => CardsComponent(configs)}
      imagenContenido={imagenContenido}
      textoContenido={textoContenido}
      textoNegro={textoNegro}
      lastSection={() => YouTubeVideoComponent(videoConfigs)}
    />
  );
}

export default Consumibles;

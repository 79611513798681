/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix = "/miniImages";

export default [
  {
    title: "Ciberseguridad",
    description:
      "Protegemos tu negocio en el mundo digital con soluciones de vanguardia y un enfoque personalizado en ciberseguridad para salvaguardar tus activos y datos críticos",
    items: [
      {
        image: `${imagesPrefix}/minivrx.png`,
        name: "VRX",
        count:
          "Convierta la gestión de vulnerabilidades en un proceso proactivo y automatizado",
        route: "/ciberseguridad/vrx",
      },
      {
        image: `${imagesPrefix}/minisophos.png`,
        name: "Sophos",
        count:
          "Encuentre diferentes soluciones de ciberseguridad según la necesidad de su empresa",
        route: "/ciberseguridad/sophos",
      },
      {
        image: `${imagesPrefix}/miniserviciosciber.png`,
        name: "Servicios Ofensivos",
        count:
          "Realice nuestros estudios ofensivos de ciberseguridad de su empresa",
        route: "/ciberseguridad/serv-ciberseguridad",
      },
    ],
  },
  {
    title: "Impresión",
    description:
      "Optimiza tus operaciones con nuestros servicios integrales de impresión, diseñados para mejorar la eficiencia y reducir costos en tu entorno de trabajo",
    items: [
      {
        image: `${imagesPrefix}/miniserviciosimpresion.png`,
        name: "Outsourcing de impresión",
        count:
          "Deje en nuestras manos la administración de su impresión y enfóquese en lo que realmente es productivo para su empresa",
        route: "/impresion/outsourcing",
      },
      {
        image: `${imagesPrefix}/minipapercut.png`,
        name: "Papercut",
        count:
          "Descubra lo sencillo y eficiente que es la gestión de impresión con nuestro software de impresión",
        route: "/impresion/papercut",
      },
      {
        image: `${imagesPrefix}/minikpax.png`,
        name: "Kpax",
        count:
          "Gestiona los suministros y alertas técnicas de tu parque de impresión con nuestro software",
        route: "/impresion/kpax",
      },
      {
        image: `${imagesPrefix}/minioriginales.png`,
        name: "Consumibles Originales HP",
        count: "Suministros",
        route: "/impresion/consumibles",
      },
    ],
  },
  {
    title: "Outsourcing de tecnología",
    description:
      "Deje en nuestras manos la administración de su impresión y enfóquese en lo que realmente es productivo para su empresa",
    items: [
      {
        image: `${imagesPrefix}/minidev.png`,
        name: "Desarrollo de software a la medida",
        count: "Digitaliza tu empresa con nuestros servicios",
        route: "/outsourcing/desarrollo-soft",
        pro: false,
      },
    ],
  },
];

import BaseLayout from "layouts/sections/components/BaseLayout";
import logoSolution2 from "assets/images/plantman.png";
import cardImage1 from "assets/images/printer.png";
import cardImage3 from "assets/images/checkshiel.jpg";
import cardImage4 from "assets/images/downlinechart.jpg";
import { columnTypes } from "layouts/sections/page-sections/page-headers";
import { CardsComponent } from "components/CardsComponent";

const configs = {
  columnType: columnTypes.TWO.id,
  linkSection: {
    show: true,
    message: "si quieres mas información ingresa aquí",
    redirectURL: "https://www.papercut.com/",
  },
  cardsTitle: "BENEFICIOS",
  styles: {
    cardsTitleStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      fontWeight: "bold",
      fontSize: "30px",
      color: "#2ea44a",
    },
  },
  sideImage: logoSolution2,
  cardsData: [
    {
      image: cardImage1,
      title: "¿Le encuentra dificíl imprimir?",
      text: "Imprima facilmente desde cualquier dispositivo, en cualquier momento. ",
    },
    {
      image: cardImage3,
      title: "¿Que había en la última impresión no recogida?",
      text: "Asegure todos los documentos, antes, durante y después de la impresión.",
    },
    {
      image: cardImage4,
      title: "¿Tu ultimo trabajo fue doble?",
      text: "Reduce tu huella y tus facturas.",
    },
    {
      text: "Cualquier marca, cualquier plataforma, todos los entornos",
    },
  ],
};

import bgImage from "assets/images/fondo-papercut.jpg";
import logoSolution from "assets/images/logo-papercut.jpg";
import imagenContenido from "assets/images/papercut-imagec.png";

const description = "Herramienta de gestión de impresión";
const textoContenido =
  "Gerencia y audita tus impresiones con el software de gestión de impresión PaperCut,  lo cual mejora tu entorno de impresión, más seguro, más fácil de administrar usuarios y más fácil de usar. ";
const textoColor = "Gestion de impresión";
const textoNegro = "Que si funciona";

function NavTabs() {
  return (
    <BaseLayout
      bgImage={bgImage}
      logoSolution={logoSolution}
      desc={description}
      cardsComponent={() => CardsComponent(configs)}
      textoContenido={textoContenido}
      colorTexto="#58B54F"
      imagenContenido={imagenContenido}
      ordenFlex={true}
      textoColor={textoColor}
      textoNegro={textoNegro}
      ordenTitulo={true}
    />
  );
}

export default NavTabs;

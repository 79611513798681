/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

// Images
import macbook from "assets/images/sobrenosotros.jpg";

const MapComponent = () => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 37.7749, // Cambia esto por la latitud de tu ubicación
    lng: -122.4194, // Cambia esto por la longitud de tu ubicación
  };

  return (
    <div style={{ width: "100%", height: "400px", overflow: "hidden" }}>
      <iframe
        width="100%"
        height="100%"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.7427596685384!2d-76.55881362260789!3d3.4127596426354856!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e30a4037eaaaaab%3A0x608119b8896100fc!2sUnidad%20Residencial%20Riberas%20del%20Rio!5e0!3m2!1ses-419!2sco!4v1718937565023!5m2!1ses-419!2sco"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

function MapGeo() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MapComponent />
            </MKBox>
          </Grid>
          <Grid
            item
            sx={12}
            md={6}
            sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}
            textAlign="center"
          >
            {" "}
            <MKTypography mt={5} variant="h4">
              CONTACTANOS
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              <p>Cali - Colombia</p> <p> Carrera 54 # 1A - 60</p>
              <p> Barrio: Cañaveralejo</p>
              <p> Tel: 602-5190949</p>
              <br />
              <MKTypography variant="h5">Contacto Comercial:</MKTypography>
              <p> Cel. 3218022066</p>
              <p>gcomercial@kinitech.com.co</p>
              <br />
              <MKTypography variant="h5">Contacto a Proveedores:</MKTypography>
              <p> Cel. 3183759561</p>
              <p>coordinacion@kinitech.com.co</p>
              <br />
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MapGeo;

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "./index.css";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

export default function MainComponent({
  bgImage,
  logoSolution,
  desc,
  cardsComponent,
  textoContenido,
  colorTexto,
  imagenContenido,
  ordenFlex,
  textoColor,
  textoNegro,
  ordenTitulo,
  soloHeader,
  lastSection = () => {},
  intermediateSection = () => {},
}) {
  console.log(ordenTitulo);
  return (
    <>
      <DefaultNavbar
        routes={[]}
        action={{
          type: "internal",
          route: "/",
          label: "Ir al inicio",
          color: "default",
        }}
        transparent
        sticky
        light
      />{" "}
      <MKBox
        display="flex"
        flexDirection="column"
        bgColor="white"
        gap="10vh"
        mb="5.5vh"
      >
        <MKBox
          minHeight={{
            xs: soloHeader ? "45vh" : "55vh",
            md: soloHeader ? "55vh" : "70vh",
          }}
          width="100%"
          sx={{
            backgroundImage: `url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "right",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "5vh",
          }}
        >
          {logoSolution && (
            <MKBox
              height={{ xs: "15vh", md: "25vh" }}
              minWidth={{ xs: "fit-content", lg: "fit-content" }}
              bgColor="transparent"
              borderRadius="15px"
            >
              <img src={logoSolution} alt="Logo" className="imagenLogo"></img>
            </MKBox>
          )}
          {desc && (
            <MKBox
              minHeight={logoSolution ? "7vh" : "10vh"}
              bgColor="rgba(120, 120, 120, 0.9)"
              borderRadius="10px"
              // minWidth="10vw"
              width={{ xs: "95%", lg: "auto" }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingX: "2vw",
              }}
            >
              <MKTypography
                fontSize={{
                  xs: "1.1rem",
                  md: logoSolution ? "2rem" : "2.5rem",
                }}
                sx={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  position: "relative",
                  color: "white !important",
                  fontWeight: "bold",
                }}
                className="texto-header"
              >
                {desc}
              </MKTypography>
            </MKBox>
          )}
        </MKBox>
        {!soloHeader && (
          <>
            <MKBox
              className="flex-contenido"
              flexDirection={{
                xs: "column",
                md: ordenFlex ? "row" : "row-reverse",
              }}
            >
              <div className="box-contenido">
                <div className="flex-tituloc">
                  <MKTypography
                    className={textoNegro ? "tituloc" : "titulocW"}
                    sx={{ color: ordenTitulo ? colorTexto : "#595456" }}
                  >
                    {textoColor}
                  </MKTypography>
                  <MKTypography
                    className={textoColor ? "tituloc" : "titulocW"}
                    sx={{ color: ordenTitulo ? "#595456" : colorTexto }}
                  >
                    {textoNegro}
                  </MKTypography>
                </div>
                <div className="box-texto">
                  <MKTypography
                    className={textoColor ? "texto-boxc" : "texto-boxcJ"}
                    fontSize={!textoColor && !textoNegro && "1.7rem !important"}
                    dangerouslySetInnerHTML={{ __html: textoContenido }}
                  >
                    {/* {textoContenido} */}
                  </MKTypography>
                </div>
              </div>
              <div className="box-contenido">
                <img src={imagenContenido} className="imagenLogo"></img>
              </div>
            </MKBox>

            {/* TIAGO BOXXXXXXXXXXXXXXXXXX */}
            {/* TIAGO BOXXXXXXXXXXXXXXXXXX */}
            {/* TIAGO BOXXXXXXXXXXXXXXXXXX */}
            {
              <MKBox
              // bgColor="red"
              // width="100%"
              >
                {intermediateSection()}
              </MKBox>
            }

            <MKBox
              // bgColor="red"
              // width="100%"
              minHeight="50vh"
            >
              {cardsComponent()}
            </MKBox>
            <MKBox
              // bgColor="red"
              // width="100%"
              justifyContent="center"
              display="flex"
            >
              {lastSection()}
            </MKBox>
          </>
        )}
      </MKBox>
    </>
  );
}
